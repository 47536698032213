import {
  activateCheckedApplicants,
  changeEnrollmentDates,
  deleteApplicantUser,
  deleteBusiness,
  getBusinessDashboard,
  getBusinesses,
  getEmployersWithEnrollmentPeriods,
  toggleActiveUser,
} from 'services/Services';
import { formatAPIMessage } from 'utils/utils';
import { Toast } from 'voltron';
import { setCriticalError } from 'state/actions/error';
import { pageLoading, pageLoaded } from './page_loading';

export const SET_EMPLOYERS = 'SET_EMPLOYERS';
export const SET_EMPLOYERS_LOADING = 'SET_EMPLOYERS_LOADING';
export const SET_CURRENT_EMPLOYER = 'SET_CURRENT_EMPLOYER';
export const SET_CURRENT_EMPLOYER_LOADING = 'SET_CURRENT_EMPLOYER_LOADING';
export const CLEAR_CURRENT_EMPLOYER = 'CLEAR_CURRENT_EMPLOYER';
export const SET_EMPLOYER_ENROLLMENT_PERIODS = 'SET_EMPLOYER_ENROLLMENT_PERIODS';

export const getEmployers = (accessToken) => async (dispatch) => {
  dispatch({
    type: SET_EMPLOYERS_LOADING,
    payload: true,
  });
  try {
    const employersResponse = await getBusinesses(accessToken);
    dispatch({
      type: SET_EMPLOYERS,
      payload: employersResponse.businesses || [],
    });
  } catch (error) {
    dispatch(
      setCriticalError({
        title: 'Get employers failed',
        body: formatAPIMessage(error),
        button: 'OK',
      }),
    );
  } finally {
    dispatch({
      type: SET_EMPLOYERS_LOADING,
      payload: false,
    });
  }
};

export const deleteEmployer = (accessToken, employer) => async (dispatch) => {
  try {
    await deleteBusiness(accessToken, employer);
    Toast.success(`${employer.name} Has Been Deleted.`);
    dispatch(getEmployers(accessToken));
  } catch (error) {
    dispatch(
      setCriticalError({
        title: 'Delete employer failed',
        body: formatAPIMessage(error),
        button: 'OK',
      }),
    );
  }
};

export const getCurrentEmployerInfo = (accessToken, employerId) => async (dispatch) => {
  dispatch(pageLoading());
  dispatch({
    type: SET_CURRENT_EMPLOYER_LOADING,
    payload: true,
  });
  try {
    const { name, applicants, enrollments, canSign, canDownload } = await getBusinessDashboard(accessToken, employerId);
    dispatch({
      type: SET_CURRENT_EMPLOYER,
      payload: {
        name,
        applicants,
        canSign,
        canDownload,
        futureCurrentEnrollments: enrollments.filter((enrollment) => !enrollment.hasPassed),
        nonEmptyEnrollments: enrollments.filter((enrollment) => enrollment.hasBatchData),
        pastEnrollments: enrollments.filter((enrollment) => enrollment.hasPassed),
      },
    });
  } catch (error) {
    dispatch(
      setCriticalError({
        title: 'Get current employer failed',
        body: formatAPIMessage(error),
        button: 'OK',
      }),
    );
  } finally {
    dispatch({
      type: SET_CURRENT_EMPLOYER_LOADING,
      payload: false,
    });
    dispatch(pageLoaded());
  }
};

export const toggleApplicantUserIsActive = (accessToken, userId, employerId) => (dispatch) => {
  dispatch(pageLoading());
  toggleActiveUser(accessToken, userId)
    .then(() => {
      dispatch(getCurrentEmployerInfo(accessToken, employerId));
    })
    .catch((error) => {
      dispatch(
        setCriticalError({
          title: 'Toggle applicant user active status failed',
          body: formatAPIMessage(error),
          button: 'OK',
        }),
      );
    })
    .finally(() => {
      dispatch(pageLoaded());
    });
};

export const activateCheckedApplicantsData = (accessToken, employerId, idList) => (dispatch) => {
  dispatch(pageLoading());
  activateCheckedApplicants(accessToken, idList)
    .then(() => {
      dispatch(getCurrentEmployerInfo(accessToken, employerId));
    })
    .catch((error) => {
      dispatch(
        setCriticalError({
          title: 'Activate checked applicants failed',
          body: formatAPIMessage(error),
          button: 'OK',
        }),
      );
    })
    .finally(() => {
      document.getElementById('select-all-applicants-for-activation').checked = false;
      dispatch(pageLoaded());
    });
};

export const removeApplicantUser = (accessToken, applicant, employerId) => async (dispatch) => {
  try {
    await deleteApplicantUser(accessToken, {
      applicantId: applicant.applicantId,
      enrollmentPeriodId: applicant.enrollmentPeriodId,
    });
    Toast.success(`${applicant.name} was deleted`);
    dispatch(getCurrentEmployerInfo(accessToken, employerId));
  } catch (error) {
    dispatch(
      setCriticalError({
        title: 'Delete applicant failed',
        body: formatAPIMessage(error),
        button: 'OK',
      }),
    );
  }
};

export const clearCurrentEmployerInfo = () => (dispatch) => {
  dispatch({
    type: CLEAR_CURRENT_EMPLOYER,
  });
};

export const modifyEnrollmentDates =
  (isAdmin, accessToken, enrollmentId, startDate, endDate, employerId) => async (dispatch) => {
    if (!isAdmin) {
      return;
    }

    try {
      const editResponse = await changeEnrollmentDates(accessToken, enrollmentId, startDate, endDate);
      Toast.success(editResponse?.message || editResponse);
      dispatch(getCurrentEmployerInfo(accessToken, employerId));
    } catch (error) {
      dispatch(
        setCriticalError({
          title: 'Modify enrollment end date failed',
          body: formatAPIMessage(error),
          button: 'OK',
        }),
      );
    }
  };

export const getEmployerEnrollmentPeriods = (accessToken) => async (dispatch) => {
  dispatch(pageLoading());
  try {
    const employerEnrollmentPeriodsResponse = await getEmployersWithEnrollmentPeriods(accessToken);
    dispatch({
      type: SET_EMPLOYER_ENROLLMENT_PERIODS,
      payload: employerEnrollmentPeriodsResponse,
    });
  } catch (error) {
    dispatch(
      setCriticalError({
        title: 'Get employer enrollment periods failed',
        body: formatAPIMessage(error),
        button: 'OK',
      }),
    );
  } finally {
    dispatch(pageLoaded());
  }
};
