import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from '@mui/material';

import { Input, Select, Button } from 'voltron';
import { validateEmail, validatePhone, validateZip } from 'utils/validator';
import { statesData } from '../../../constants/states';
import { UPDATE_UPLOAD_CENSUS_DATA } from '../../../state/actions/upload';

const labelKind = 'regular';
const NEW_EMPLOYER_OPTION = [
  {
    label: 'New Employer',
    value: 'new',
  },
];

const validateOptionalFields = (values, errors) => {
  const formErrors = { ...errors };
  if (values.phone && !validatePhone(values.phone)) {
    formErrors.phone = 'phone';
  }
  if (values.email && !validateEmail(values.email)) {
    formErrors.email = 'email';
  }

  return formErrors;
};

const validate = (values) => {
  let errors = {};
  if ([null, '', undefined].includes(values?.id)) {
    errors.employer = 'employer';
  }
  if (!values.name) {
    errors.name = 'name';
  }
  if (!values.address) {
    errors.address = 'address';
  }
  if (!values.city) {
    errors.city = 'city';
  }
  if (!values.stateCd) {
    errors.state = 'state';
  }
  if (!values.zipCode || !validateZip(values.zipCode)) {
    errors.zipCode = 'zip';
  }
  errors = validateOptionalFields(values, errors);
  return errors;
};

const employerSelectHandler = (value, employers, formData, setFormErrors, dispatch) => {
  setFormErrors({
    employer: value === '',
  });
  let values;
  if (value === 'new') {
    values = {
      id: 0,
      name: '',
      address: '',
      city: '',
      stateCd: '',
      zipCode: '',
      phone: '',
      email: '',
    };
  } else {
    const employerObj = employers?.find((employer) => +employer.id === +value);
    values = {
      ...employerObj,
      id: +value,
      name: employerObj?.name,
      address: employerObj?.address,
      city: employerObj?.city,
      stateCd: employerObj?.stateCd,
      zipCode: employerObj?.zipCode || '',
      phone: employerObj?.phone || '',
      email: employerObj?.email || '',
    };
  }
  dispatch({
    type: UPDATE_UPLOAD_CENSUS_DATA,
    payload: {
      formData: {
        ...formData,
        employer: {
          ...formData.employer,
          ...values,
        },
      },
    },
  });
};

const handleInputChange = (name, value, formData, formErrors, setFormErrors, dispatch) => {
  setFormErrors({
    ...formErrors,
    [name]: value === '' && name,
  });
  dispatch({
    type: UPDATE_UPLOAD_CENSUS_DATA,
    payload: {
      formData: {
        ...formData,
        employer: {
          ...formData.employer,
          [name]: value,
        },
      },
    },
  });
};

const handleSubmit = (formData, setFormErrors, proceedToTab) => {
  const errors = validate(formData.employer);
  setFormErrors(errors);
  if (Object.keys(errors).length === 0) {
    proceedToTab(1);
  }
};

const getRequiredEmployerFields = ({ formData, formErrors, setFormErrors, dispatch }) => (
  <>
    <Grid item xs={12}>
      <Input
        key={`employer-name-${formData.employer?.id}`}
        name="name"
        id="name"
        label="Employer Name"
        labelKind={labelKind}
        placeholder="Employer Name"
        material={false}
        autoComplete={false}
        onChange={(e) => handleInputChange('name', e.target.value, formData, formErrors, setFormErrors, dispatch)}
        value={formData.employer.name}
        data-testid="employer-name"
        required
        kind="text"
        forceErrorMessage={formErrors.name}
        maxLength={40}
      />
    </Grid>
    <Grid item xs={12}>
      <Input
        name="address"
        id="address"
        label="Address"
        labelKind={labelKind}
        placeholder="Address"
        material={false}
        autoComplete={false}
        onChange={(e) => handleInputChange('address', e.target.value, formData, formErrors, setFormErrors, dispatch)}
        value={formData.employer.address}
        required
        kind="text"
        data-testid="employer-address"
        maxLength={40}
        forceErrorMessage={formErrors.address}
      />
    </Grid>
    <Grid item xs={12}>
      <Input
        name="city"
        id="city"
        label="City"
        labelKind={labelKind}
        kind="text"
        placeholder="City"
        material={false}
        autoComplete={false}
        onChange={(e) => handleInputChange('city', e.target.value, formData, formErrors, setFormErrors, dispatch)}
        value={formData.employer.city}
        data-testid="employer-city"
        required
        maxLength={30}
        forceErrorMessage={formErrors.city}
      />
    </Grid>
    <Grid item xs={12} container justifyContent="space-between">
      <Grid item xs={8.8}>
        <Select
          label="State"
          labelKind={labelKind}
          id="stateCd"
          key={`stateCd-${formData.employer?.id}`}
          labelPosition="top"
          name="stateCd"
          options={statesData}
          clearable={false}
          searchable={false}
          material={false}
          selectedValue={statesData.find((option) => option.label === formData.employer.stateCd)}
          onChange={(state) => handleInputChange('stateCd', state.label, formData, formErrors, setFormErrors, dispatch)}
          required
          forceErrorMessage={formErrors.state}
        />
      </Grid>
      <Grid item xs={2.8}>
        <Input
          name="zip"
          kind="zip"
          id="zip"
          label="Zip"
          labelKind={labelKind}
          placeholder="Zip"
          material={false}
          autoComplete={false}
          onChange={(e) => handleInputChange('zipCode', e.target.value, formData, formErrors, setFormErrors, dispatch)}
          maxLength={10}
          value={formData.employer.zipCode}
          data-testid="employer-zip"
          required
          forceErrorMessage={formErrors.zipCode}
        />
      </Grid>
    </Grid>
  </>
);

const getOptionalEmployerFields = ({ formData, formErrors, setFormErrors, dispatch }) => (
  <>
    <Grid item xs={12}>
      <Input
        name="phone"
        id="phone"
        label="Phone"
        labelKind={labelKind}
        placeholder="Phone"
        material={false}
        autoComplete={false}
        onChange={(e) => handleInputChange('phone', e.target.value, formData, formErrors, setFormErrors, dispatch)}
        value={formData.employer.phone}
        kind="phone"
        data-testid="employer-phone"
        forceErrorMessage={formErrors.phone}
      />
    </Grid>
    <Grid item xs={12}>
      <Input
        name="email"
        id="email"
        label="Email Address"
        labelKind={labelKind}
        placeholder="Email"
        material={false}
        autoComplete={false}
        onChange={(e) => handleInputChange('email', e.target.value, formData, formErrors, setFormErrors, dispatch)}
        value={formData.employer.email}
        data-testid="employer-email"
        kind="email"
        forceErrorMessage={formErrors.email}
        maxLength={50}
      />
    </Grid>
  </>
);

const Employer = ({ proceedToTab, enableNextTab }) => {
  const { formData = {} } = useSelector((state) => state.upload);
  const { employers } = useSelector((state) => state.employers);
  const dispatch = useDispatch();
  const [formErrors, setFormErrors] = useState({});
  const employerList = employers.map((employer) => ({
    label: employer.name,
    value: employer.id,
  }));

  useEffect(() => {
    proceedToTab(0);
  }, []);

  useEffect(() => {
    const errors = validate(formData.employer);
    if (Object.keys(errors).length === 0) {
      setFormErrors({});
      enableNextTab(1);
    } else {
      enableNextTab(1, false);
    }
  }, [formData.employer]);

  return (
    <div>
      <Grid container spacing={0} className="innerHeader">
        <Grid item xs={12}>
          <h3>Employer Information</h3>
        </Grid>
        <Grid item xs={12}>
          <p>Select or enter the employer information for the census being uploaded</p>
        </Grid>
      </Grid>
      <form>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Select
              label="Select Employer"
              labelKind={labelKind}
              id="employer"
              labelPosition="top"
              name="employer"
              required
              options={[...NEW_EMPLOYER_OPTION, ...employerList]}
              clearable={false}
              searchable={false}
              material={false}
              selectedValue={[...NEW_EMPLOYER_OPTION, ...employerList].find(
                (option) => option.value === formData.employer?.id,
              )}
              onChange={(employer) =>
                employerSelectHandler(employer.value, employers, formData, setFormErrors, dispatch)
              }
              forceErrorMessage={formErrors.employer}
            />
            <span>If new employer, select &apos;New Employer&apos; to enter employer information</span>
          </Grid>
          {getRequiredEmployerFields({ formData, formErrors, setFormErrors, dispatch })}
          {getOptionalEmployerFields({ formData, formErrors, setFormErrors, dispatch })}
          <Grid item xs={12} justifyContent="flex-end" container>
            <Button kind="primary" onClick={() => handleSubmit(formData, setFormErrors, proceedToTab)}>
              CONTINUE
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default Employer;
