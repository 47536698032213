/* eslint-disable react/no-danger */
import { useEffect } from 'react';
import { Switch, useHistory } from 'react-router-dom';
import { Security, SecureRoute } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { init as initApm } from '@elastic/apm-rum';
import { ApmRoute } from '@elastic/apm-rum-react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from '@mui/material';
import { Modal, Button } from 'voltron';

import { clearErrors } from 'state/actions/error';

// Constants
import routes from 'constants/routes';
import { isAdminUser } from 'utils/utils';
import { API_COMMON_ERROR_MESSAGE } from 'constants';

// CSS
import './styles/index.scss';
import './styles/common.scss';

// Components
import Layout from 'containers/Layout/Layout';
import Login from 'containers/Login/Login';
import Home from 'views/home/Home';
import Applicant from 'views/dashboard/applicant/Applicant';
import EnrollmentsDashboard from 'views/dashboard/enrollments/EnrollmentsDashboard';
import EnrollmentForm from 'views/dashboard/applicant/forms/EnrollmentForm';
import NonApplicantUsers from 'views/nonApplicantUsers/NonApplicantUsers';
import UserForm from 'views/nonApplicantUsers/userForm/UserForm';
import EmployersDashboard from 'views/employers/EmployersDashboard';
import EditEmployer from 'views/employers/editEmployer/EditEmployer';
import EmployerApplicantsDashboard from 'views/dashboard/employerApplicants/EmployerApplicantsDashboard';
import AdminReports from 'views/adminReports/AdminReports';
import AgenciesDashboard from 'views/agencies/AgenciesDashboard';
import EditAgency from 'views/agencies/editAgency/EditAgency';
import OwningCompaniesDashboard from 'views/owningCompanies/OwningCompaniesDashboard';
import EditOwningCompany from 'views/owningCompanies/editOwningCompany/EditOwningCompany';
import Uploads from 'views/uploads/Uploads';
import NotFound from 'components/NotFound/notFound';

import oktaConfig from './okta_config';
import packageJson from '../package.json';

const urlEnvMap = {
  localhost: 'local',
  'digsi-d.ameritas.com': 'dev',
  'digsi-t.ameritas.com': 'test',
  'digsi-m.ameritas.com': 'model',
  'digsi.ameritas.com': 'prod',
};

initApm({
  serviceName: 'DIGSI',
  serverUrl:
    urlEnvMap[window.location.hostname] === 'prod' ? 'https://elkapm.ameritas.com' : 'https://elkrum-m.ameritas.com',
  serviceVersion: packageJson.version,
  environment: urlEnvMap[window.location.hostname],
});

const oktaAuth = new OktaAuth(oktaConfig.oidc);

const App = () => {
  const history = useHistory();
  const { isCriticalError, message } = useSelector((state) => state.error);
  const { userInfo } = useSelector((state) => state.appState);
  const dispatch = useDispatch();

  const isAdmin = isAdminUser(userInfo);

  const customAuthHandler = () => {
    history.push(routes.login);
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '', window.location.origin));
  };

  const handleErrorModalClose = () => {
    dispatch(clearErrors());
  };

  useEffect(() => {
    const currentSiteLocation = window.location;
    let timeout;
    if (
      currentSiteLocation.pathname.includes(routes.login) &&
      currentSiteLocation.pathname !== routes.login &&
      currentSiteLocation.search
    ) {
      history.replace(routes.home);
      timeout = setTimeout(() => {
        history.go(0);
      }, 1000);
    }

    return () => clearTimeout(timeout);
  }, [window.location]);

  return (
    <Security oktaAuth={oktaAuth} onAuthRequired={customAuthHandler} restoreOriginalUri={restoreOriginalUri}>
      <Layout>
        {isCriticalError && (
          <Modal open title={message?.title} handleToggle={handleErrorModalClose}>
            <div
              tabIndex="0"
              dangerouslySetInnerHTML={{ __html: isAdmin ? message?.body : API_COMMON_ERROR_MESSAGE }}
            />
            <Grid justifyContent="flex-end" container>
              <Button name="modal-close-btn" onClick={handleErrorModalClose}>
                {message?.button}
              </Button>
            </Grid>
          </Modal>
        )}
        <Switch>
          <ApmRoute path={routes.login} component={Login} />
          <SecureRoute exact path={routes.home} component={Home} />
          <SecureRoute exact path={routes.applicantDashboard} component={Applicant} />
          <SecureRoute exact path={routes.userDashboard} component={EnrollmentsDashboard} />
          <SecureRoute exact path={routes.enrollment} component={EnrollmentForm} />
          <SecureRoute exact path={routes.increases} render={() => <EnrollmentForm isIncreases />} />
          <SecureRoute exact path={routes.users} component={NonApplicantUsers} />
          <SecureRoute exact path={routes.userForm} component={UserForm} />
          <SecureRoute exact path={routes.businesses} component={EmployersDashboard} />
          <SecureRoute exact path={routes.businessForm} component={EditEmployer} />
          <SecureRoute exact path={routes.employerApplicantsDashboard} component={EmployerApplicantsDashboard} />
          <SecureRoute
            exact
            path={routes.employerApplicantsDashboardForUser}
            render={() => <EmployerApplicantsDashboard forUser />}
          />
          <SecureRoute exact path={routes.adminReports} component={AdminReports} />
          <SecureRoute exact path={routes.agencies} component={AgenciesDashboard} />
          <SecureRoute exact path={routes.agencyForm} component={EditAgency} />
          <SecureRoute exact path={routes.owningCompanies} component={OwningCompaniesDashboard} />
          <SecureRoute exact path={routes.owningCompanyForm} component={EditOwningCompany} />
          <SecureRoute exact path={routes.uploads} component={Uploads} />
          <ApmRoute path="*" component={NotFound} />
        </Switch>
      </Layout>
    </Security>
  );
};

export default App;
