import HttpCalls from './ApiCalls';

const getHeaders = (accessToken, contentType = 'application/json') => ({
  headers: {
    'Content-Type': contentType,
    Authorization: `Bearer ${accessToken}`,
  },
});

const doGetCall = (endpoint, headers, data, responseType) => {
  const { doCall } = HttpCalls;

  return doCall('GET', endpoint, headers, data, responseType);
};

const doPostCall = (endpoint, headers, request, responseType) => {
  const { doCall } = HttpCalls;

  return doCall('POST', endpoint, headers, request, responseType);
};

export const getDashboard = (accessToken) => {
  const headers = getHeaders(accessToken);

  return doGetCall('main/getDashboard', headers);
};

export const getBusinessDashboard = (accessToken, businessId) => {
  const headers = getHeaders(accessToken);

  return doGetCall(`main/getBusinessDashboard?busId=${businessId}`, headers);
};

export const deleteApplicantUser = (accessToken, request) => {
  const headers = getHeaders(accessToken);

  return doPostCall('main/deleteApplicant', headers, request);
};

export const downloadApplicantPdf = (accessToken, request) => {
  const headers = getHeaders(accessToken);

  return doPostCall('main/downloadPdf', headers, request, 'arraybuffer');
};

export const getSigningUrl = (accessToken, request) => {
  const headers = getHeaders(accessToken);

  return doPostCall('main/getSigningUrl', headers, request);
};

export const getAllNonApplicantUsers = (accessToken) => {
  const headers = getHeaders(accessToken);

  return doGetCall('main/getAllNonApplicants', headers);
};

export const toggleActiveUser = (accessToken, userId) => {
  const headers = getHeaders(accessToken);

  return doPostCall(`main/toggleActiveUser?id=${userId}`, headers);
};

export const activateCheckedApplicants = (accessToken, idList) => {
  const headers = getHeaders(accessToken);
  return doPostCall(`main/activateCheckedUsers?idList=${idList}`, headers);
};

export const getApplicantInfo = (accessToken, userFormId) => {
  const headers = getHeaders(accessToken);

  if (userFormId) {
    return doGetCall(`main/GetApplicationInfo/?userFormId=${userFormId}`, headers);
  }
  return doGetCall('main/getApplicantInfo', headers);
};

export const getBusinesses = (accessToken) => {
  const headers = getHeaders(accessToken);

  return doGetCall('main/getBusinesses', headers);
};

export const getBusiness = (accessToken, businessId) => {
  const headers = getHeaders(accessToken);

  return doGetCall(`main/getBusiness?busId=${businessId}`, headers);
};

export const exportBusinesses = (accessToken) => {
  const headers = getHeaders(accessToken);

  return doGetCall('report/ExportBusinesses', headers, {}, 'arraybuffer');
};

export const deleteBusiness = (accessToken, request) => {
  const headers = getHeaders(accessToken);

  return doPostCall('main/deleteBusiness', headers, request);
};

export const saveBusiness = (accessToken, request) => {
  const headers = getHeaders(accessToken);

  return doPostCall('main/saveBusiness', headers, request);
};

export const getOwnerCompanies = (accessToken) => {
  const headers = getHeaders(accessToken);

  return doGetCall('main/getOwnerCompanies', headers);
};

export const getAgencies = (accessToken) => {
  const headers = getHeaders(accessToken);

  return doGetCall('main/getAgencies', headers);
};

export const getEnrollmentTypes = (accessToken) => {
  const headers = getHeaders(accessToken);

  return doGetCall('main/GetEnrollmentTypes', headers);
};

export const getAgents = (accessToken) => {
  const headers = getHeaders(accessToken);

  return doGetCall('main/getAgents', headers);
};

export const getClassPlanTypes = (accessToken) => {
  const headers = getHeaders(accessToken);

  return doGetCall('main/GetClassPlanTypes', headers);
};

export const getUserWholesalers = (accessToken) => {
  const headers = getHeaders(accessToken);

  return doGetCall('main/getUserWholesalers', headers);
};

export const previewFile = (accessToken, formData) => {
  const headers = getHeaders(accessToken, 'multipart/form-data');

  return doPostCall('main/PreviewCensusInformation', headers, formData);
};

export const validateCensusModel = (accessToken, requestData) => {
  const headers = getHeaders(accessToken);

  return doPostCall('main/ValidateCensusModel', headers, requestData);
};

export const uploadCensus = (accessToken, requestData) => {
  const headers = getHeaders(accessToken, 'multipart/form-data');

  return doPostCall('main/UploadCensus', headers, requestData);
};

export const setApplication = (accessToken, request) => {
  const headers = getHeaders(accessToken);

  return doPostCall('main/SetApplication', headers, request);
};

export const saveFormInputs = (accessToken, request) => {
  const headers = getHeaders(accessToken);

  return doPostCall('main/saveFormInputs', headers, request);
};

export const getUserEdit = (accessToken, id) => {
  const headers = getHeaders(accessToken);

  return doGetCall(`main/getUserEdit/?id=${id}`, headers);
};

export const getUserRoles = (accessToken) => {
  const headers = getHeaders(accessToken);

  return doGetCall('main/getUserRoles', headers);
};

export const saveNonApplicantUser = (accessToken, request) => {
  const headers = getHeaders(accessToken);

  return doPostCall('main/saveNonApplicantUser', headers, request);
};

export const sendToAdobe = (accessToken, request) => {
  const headers = getHeaders(accessToken);

  return doPostCall('main/sendToAdobe', headers, request);
};

export const refuseOverallCoverage = (accessToken, request) => {
  const headers = getHeaders(accessToken);

  return doPostCall('main/RefuseOverallCoverage', headers, request);
};

export const exportNonApplicants = (accessToken) => {
  const headers = getHeaders(accessToken);

  return doGetCall('report/exportAllNonApplicants', headers, {}, 'arraybuffer');
};

export const exportAllApplicants = (accessToken, employerId) => {
  const headers = getHeaders(accessToken);

  return doGetCall(`report/ExportAllApplicants?businessId=${employerId}`, headers, {}, 'arraybuffer');
};

export const exportAllInactiveApplicants = (accessToken, employerId) => {
  const headers = getHeaders(accessToken);

  return doGetCall(`report/ExportAllInactiveApplicants?businessId=${employerId}`, headers, {}, 'arraybuffer');
};

export const exportAllSubmittedApplicants = (accessToken, employerId) => {
  const headers = getHeaders(accessToken);

  return doGetCall(`report/AllSubmittedApplicants?businessId=${employerId}`, headers, {}, 'arraybuffer');
};

export const exportAllRefusedCoverageApplicants = (accessToken, employerId) => {
  const headers = getHeaders(accessToken);

  return doGetCall(`report/AllRefused?businessId=${employerId}`, headers, {}, 'arraybuffer');
};

export const changeEnrollmentDates = (accessToken, enrollmentId, startDate, endDate) => {
  const headers = getHeaders(accessToken);

  const request = {
    id: enrollmentId,
    startDate,
    endDate,
  };

  return doPostCall('main/ChangeEnrollmentDates', headers, request);
};

export const generateBatchReport = (accessToken, enrollmentId) => {
  const headers = getHeaders(accessToken);

  return doGetCall(`report/GenerateBatchReport?enrollmentId=${enrollmentId}`, headers, {}, 'arraybuffer');
};

export const getEmployersWithEnrollmentPeriods = (accessToken) => {
  const headers = getHeaders(accessToken);

  return doGetCall('main/GetEmployersWithEnrollments', headers);
};

export const runAdminReports = (accessToken, reportIds, employerId, enrollmentPeriodIds) => {
  const headers = getHeaders(accessToken);

  const request = {
    reportIds,
    employerId,
    enrollmentPeriodIds,
  };

  return doPostCall(`report/RunAdminReport`, headers, request, 'arraybuffer');
};

export const exportAgencies = (accessToken) => {
  const headers = getHeaders(accessToken);

  return doGetCall('report/exportAgencies', headers, {}, 'arraybuffer');
};

export const deleteAgency = (accessToken, request) => {
  const headers = getHeaders(accessToken);

  return doPostCall('main/deleteAgency', headers, request);
};

export const getAgency = (accessToken, agencyId) => {
  const headers = getHeaders(accessToken);

  return doGetCall(`main/getAgency?id=${agencyId}`, headers);
};

export const saveAgency = (accessToken, request) => {
  const headers = getHeaders(accessToken);

  return doPostCall('main/saveAgency', headers, request);
};

export const exportOwningCompanies = (accessToken) => {
  const headers = getHeaders(accessToken);

  return doGetCall('report/exportOwners', headers, {}, 'arraybuffer');
};

export const deleteOwningCompany = (accessToken, request) => {
  const headers = getHeaders(accessToken);

  return doPostCall('main/deleteOwner', headers, request);
};

export const getOwningCompany = (accessToken, ownerCompanyId) => {
  const headers = getHeaders(accessToken);

  return doGetCall(`main/getOwner?id=${ownerCompanyId}`, headers);
};

export const saveOwningCompany = (accessToken, request) => {
  const headers = getHeaders(accessToken);

  return doPostCall('main/saveOwner', headers, request);
};

export const updateLastLoginDate = (accessToken) => {
  const headers = getHeaders(accessToken);

  return doPostCall('main/updateLastLoginDate', headers);
};
